import React from "react";
import "./Projects.css";
import Img1 from "../src/projects/1.jpg";
import Img2 from "../src/projects/2.jpg";
import Img3 from "../src/projects/3.jpg";
import Img4 from "../src/projects/4.jpg";
import Img5 from "../src/projects/5.jpg";
import Img6 from "../src/projects/6.jpg";
import Img7 from "../src/projects/7.jpg";
import Img8 from "../src/projects/8.jpg";
import Img9 from "../src/projects/9.jpg";
import Img10 from "../src/projects/10.jpg";
import Img11 from "../src/projects/11.jpg";
import Img12 from "../src/projects/12.jpg";
import Img13 from "../src/projects/13.jpg";
import Img14 from "../src/projects/14.jpg";
import Img15 from "../src/projects/15.jpg";
import Img17 from "../src/projects/17.jpg";
import Img18 from "../src/projects/18.jpg";
import Img19 from "../src/projects/19.jpg";
import Img20 from "../src/projects/20.jpg";
import Img21 from "../src/projects/21.jpg";
import Img22 from "../src/projects/22.jpg";
import Img23 from "../src/projects/23.jpg";
import Img24 from "../src/projects/24.jpg";
import Img25 from "../src/projects/25.jpg";
import Img26 from "../src/projects/27.jpg";
import Img27 from "../src/projects/28.jpg";
import Img28 from "../src/projects/29.jpg";
import Img29 from "../src/projects/30.jpg";
import Img30 from "../src/projects/31.jpg";
import Img31 from "../src/projects/32.jpg";
import Img32 from "../src/projects/33.jpg";
import Img33 from "../src/projects/34.jpg";
import Img34 from "../src/projects/35.jpg";
import Con1 from "../src/projects/c-2.jpg";
import Con2 from "../src/projects/c-3.jpg";
import Con3 from "../src/projects/c-4.jpg";
import Con4 from "../src/projects/c-6.jpg";
import Con5 from "../src/projects/c-7.jpg";
import Con6 from "../src/projects/c-9.jpg";
import Con7 from "../src/projects/c-10.jpg";
import Con8 from "../src/projects/c-11.jpg";
import Con9 from "../src/projects/c-12.jpg";
import Con10 from "../src/projects/c-13.jpg";
import Con11 from "../src/projects/c-14.jpg";
import Con12 from "../src/projects/c-15.jpg";

const Projects = () => {
  return (
    <div className="projects-section">
      <div className="projects-header">
        <h2 className="post-title">
          <span>Ferentix</span> Projects
        </h2>
        <p className="subheading">
        At Ferentix (Pvt) Ltd, we specialize in delivering cutting-edge 
        solar energy solutions designed to meet the unique needs of each 
        project. Our portfolio showcases a range of successful solar 
        installations, including residential rooftop systems, commercial 
        solar farms, and hybrid energy solutions. We focus on integrating 
        the latest technologies to maximize efficiency, reduce energy 
        costs, and promote sustainability. From on-grid to off-grid and 
        hybrid solar systems, Ferentix ensures every project is executed 
        with precision and long-term reliability. </p>
      </div>
      <div className="projects-body">
        <div className="body-title">
          <h2>Solar Projects</h2>
        </div>
        <div className="body-gallery">
          <img src={Img1} alt="" />
          <img src={Img2} alt="" />
          <img src={Img3} alt="" />
          <img src={Img4} alt="" />
          <img src={Img5} alt="" />
          <img src={Img6} alt="" />
          <img src={Img7} alt="" />
          <img src={Img8} alt="" />
          <img src={Img9} alt="" />
          <img src={Img10} alt="" />
          <img src={Img11} alt="" />
          <img src={Img12} alt="" />
          <img src={Img13} alt="" />
          <img src={Img14} alt="" />
          <img src={Img15} alt="" />
          <img src={Img17} alt="" />
          <img src={Img18} alt="" />
          <img src={Img19} alt="" />
          <img src={Img20} alt="" />
          <img src={Img21} alt="" />
          <img src={Img22} alt="" />
          <img src={Img23} alt="" />
          <img src={Img24} alt="" />
          <img src={Img25} alt="" />
          <img src={Img26} alt="" />
          <img src={Img27} alt="" />
          <img src={Img28} alt="" />
          <img src={Img29} alt="" />
          <img src={Img30} alt="" />
          <img src={Img31} alt="" />
          <img src={Img32} alt="" />
          <img src={Img33} alt="" />
          <img src={Img34} alt="" />
        </div>
        
        <div className="body-title">
          <h2>Construction Projects</h2>
        </div>
        <div className="body-gallery">
          <img src={Con1} alt="" />
          <img src={Con2} alt="" />
          <img src={Con3} alt="" />
          <img src={Con5} alt="" />
          <img src={Con6} alt="" />
          <img src={Con4} alt="" />
          <img src={Con7} alt="" />
          <img src={Con8} alt="" />
          <img src={Con9} alt="" />
          <img src={Con10} alt="" />
          <img src={Con11} alt="" />
          <img src={Con12} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Projects;
